import React, { useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  notification
} from "antd";
const { Option } = Select;

const regisData = {
  components: {
    mobile: {
      name: "registrationMobileComponent"
    },
    desktop: {
      name: "registration"
    }
  },
  data: {
    values: {
      title: "STUDY ABROAD",
      image_path_1: "registration_header_image",
      image_path_2: "distance_image",
      tag_choice: "multiple",
      tags: [
        "Course Selection",
        "Scholarship",
        "Resume analysis",
        "Visa guidance"
      ],
      countries: [
        "Australia",
        "New Zealand",
        "Canada",
        "Germany",
        "USA",
        "Finland",
        "Ireland",
        "Malta",
        "Croatia",
        "France",
        "Italy",
        "Slovenia",
        "Spain",
        "Singapore"
      ],
      intakes: [
        {
          title: "MBA",
          description: " ",
          image_path: ""
        },
        {
          title: "MCA",
          description: " ",
          image_path: ""
        },
        {
          title: "BBA",
          description: " ",
          image_path: ""
        },
        {
          title: "BCA",
          description: " ",
          image_path: ""
        },
        {
          title: "BCom",
          description: " ",
          image_path: ""
        },
        {
          title: "BTech",
          description: " ",
          image_path: ""
        },
        {
          title: "MTech",
          description: " ",
          image_path: ""
        },
        {
          title: "BA",
          description: " ",
          image_path: ""
        },
        {
          title: "MA",
          description: " ",
          image_path: ""
        },
        {
          title: "MSc",
          description: " ",
          image_path: ""
        },

        {
          title: "BEd",
          description: " ",
          image_path: ""
        },
        {
          title: "PGDM",
          description: " ",
          image_path: ""
        }
      ],
      tnc: "/terms-and-conditions"
    }
  },
  layout: {
    mobile: {},
    desktop: {},
    headerColor: "",
    backgroundColor: "bg-color-blue"
  }
};

const RegistrationForm = props => {
  const [isLoading, setIsLoading] = useState(false);
  const { image_path_1, image_path_2, intakes } = regisData.data.values;

  const handleSubmit = async e => {
    e.preventDefault();
    const values = await props.form.validateFieldsAndScroll();
    try {
      setIsLoading(true);
      const {
        name,
        email,
        phone,
        preferred_course: interested_courses,
        has_agreed_to_terms
      } = values;
      const response = await axios.post(
        "https://www.entrancezone.com/api/v1/distance",
        {
          name,
          email,
          phone,
          interested_courses,
          has_agreed_to_terms
        }
      );
      notification.success({
        message: response.data.message
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Something went wrong"
      });
    }
  };

  const { getFieldDecorator } = props.form;
  const validateMobileNo = (rule, value, callback) => {
    if (isNaN(value)) {
      callback("!! No. should only be in digits !!");
      return;
    }
    callback();
  };
  const checkCheckBox = (rule, value, callback) => {
    if (!value) {
      callback("Please agree the terms and conditions!");
    } else {
      callback();
    }
  };
  return (
    <Row className="register-text">
      <Col
        xs={24}
        md={12}
        lg={12}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div style={{ padding: "7rem 10rem 10rem 0rem" }}>
          <img
            src={require("../images/dist-edu.svg")}
            alt=""
            className=""
            style={{ width: "85%", marginTop: "1rem" }}
          />
        </div>
      </Col>
      <Col xs={24} md={12}>
        <div className="register-form-column">
          <div className="register-form">
            <Form
              onSubmit={handleSubmit}
              style={{ textAlign: "initial" }}
              hideRequiredMark
            >
              <div className="registration-ant-form">
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your name!",
                        whitespace: true
                      }
                    ]
                  })(
                    <Input
                      placeholder="Enter your name"
                      className="register-input"
                    />
                  )}
                </Form.Item>

                <Form.Item label="E-mail">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "The input is not valid E-mail!"
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!"
                      }
                    ]
                  })(
                    <Input
                      placeholder="Enter your Email"
                      className="register-input"
                    />
                  )}
                </Form.Item>

                <Form.Item label="Phone Number">
                  {getFieldDecorator("phone", {
                    rules: [
                      {
                        required: true,
                        message: "Phone is required"
                      },
                      {
                        len: 10,
                        type: "string",
                        message: "Please enter 10 digit number"
                      },
                      {
                        validator: validateMobileNo
                      }
                    ]
                  })(
                    <Input
                      placeholder="Enter your phone number"
                      className="register-input"
                      style={{ width: "100%" }}
                      maxLength={10}
                    />
                  )}
                </Form.Item>
                <Row type="flex" justify="center" gutter={[20, 20]}>
                  <Col xs={24} md={24}>
                    <Form.Item label="Preferred Course" hasFeedback>
                      {getFieldDecorator("preferred_course", {
                        rules: [
                          {
                            required: true,
                            message: "please select a Course "
                          }
                        ]
                      })(
                        <Select
                          placeholder="Select a Course"
                          className="register-select"
                          showArrow={true}
                          mode="multiple"
                        >
                          {intakes.map(intake => (
                            <Option key={intake.title} value={intake.title}>
                              {intake.title}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  {getFieldDecorator("has_agreed_to_terms", {
                    valuePropName: "checked",
                    rules: [{ validator: checkCheckBox }]
                  })(
                    <Checkbox className="register-checkbox">
                      I accept all the
                      <a
                        href="https://www.entrancezone.com/engineering/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        terms and conditions
                      </a>
                    </Checkbox>
                  )}
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  block
                >
                  SUBMIT
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const WrappedRegistrationForm = Form.create({ name: "register" })(
  RegistrationForm
);

export default WrappedRegistrationForm;
