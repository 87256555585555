import React from "react";
import { Row, Col, Card } from "antd";
import Heading from "./Heading";

const Highlight = ({ data, layout }) => {
  const { values } = data;
  const { headerColor } = layout;
  return (
    <React.Fragment>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
        />
      )}
      <Row
        className="highlight-card"
        type="flex"
        justify="center"
        gutter={[{ xs: 0, md: 0, lg: 0 }, 0]}
      >
        {values.map(highlight => {
          return (
            <Col xs={24} md={8} lg={8} key={highlight.title}>
              <Card>
                <img
                  alt={highlight.title}
                  src={require(`../images/highlight-images/${highlight.image_path}.svg`)}
                />
                <h2>{highlight.title}</h2>
                <p>{highlight.description}</p>
              </Card>
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};
export default Highlight;
