import React from "react";
import { Card, Carousel } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heading from "./Heading";
const { Meta } = Card;

const Countries = ({ data, layout, component_id, isMobile }) => {
  const { values } = data;
  const { headerColor } = layout;

  const setting = {
    dots: true,
    infinite: true,
    slidesToShow: isMobile ? 2 : 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    focusOnSelect: true,
    pauseOnHover: true,
    swipeToSlide: true
  };

  return (
    <React.Fragment>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
          component_id={component_id}
        />
      )}

      <Carousel className="testimonial" {...setting}>
        {values.map(country => {
          return (
            <Card
              key={`${country.name}`}
              className="card"
              cover={
                <img
                  className="countries-img"
                  alt={`${country.image_path}-flag`}
                  src={require(`../images/flags/${country.image_path}.png`)}
                />
              }
            >
              <Meta className="countries-text" title={country.name} />
            </Card>
          );
        })}
      </Carousel>
    </React.Fragment>
  );
};

export default Countries;
