import React from "react";
import Heading from "./Heading";
import { Row, Col, Card } from "antd";

const Quality = ({ data, layout }) => {
  const { values } = data;
  const { headerColor, gridConfig } = layout;

  return (
    <div>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
        />
      )}
      <Row
        className="quality-card"
        type="flex"
        justify="center"
        gutter={[{ xs: 24, md: 32, lg: 48 }, 16]}
      >
        {values.map(highlight => {
          return (
            <Col {...gridConfig} key={highlight.title}>
              <Card>
                <h2
                  className={
                    data.heading.title === "Learning Experience"
                      ? "half-underline"
                      : "no-underline"
                  }
                >
                  {highlight.title}
                </h2>
                {data.heading.title === "Learning Experience" ? (
                  <p>{highlight.description}</p>
                ) : null}
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Quality;
