import React, { useEffect, useState } from "react";
import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";
import { Test } from "../components";
import { Row, Col, Result } from "antd";
import { enquireScreen } from "enquire-js";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import elogo from "../images/elogo.svg";
import axios from "axios";
import "../less/antMotionStyle.less";
configureAnchors({ scrollDuration: 1000 });

export const HeaderDataSource = {
  wrapper: { className: "header0 home-page-wrapper" },
  page: { className: "home-page" },
  logo: {
    className: "header0-logo",
    children: elogo
  },
  Menu: {
    className: "header0-menu",
    children: [
      {
        name: "item0",
        a: { children: "Courses", href: "#courses" }
      },
      // {
      //   name: "item1",
      //   a: {
      //     children: "Universities",
      //     href: "#universities"
      //   }
      // },
      {
        name: "item1",
        a: {
          children: "Why EntranceZone",
          href: "#services"
        }
      },
      {
        name: "item2",
        a: {
          children: "Learning Experience",
          href: "#qualities"
        }
      },
      {
        name: "item3",
        a: {
          children: "Highlights",
          href: "#highlights"
        }
      }
    ]
  },
  mobileMenu: { className: "header0-mobile-menu" }
};

const HomePageComponent = () => {
  const [isMobile, setIsMobile] = useState();
  const [collegeData, setCollegeData] = useState({});
  const { title, description, data } = collegeData;
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorSubtitle, setErrorSubtitle] = useState("");
  const bucket =
    process.env.NODE_ENV === "production" ? "ezap-prod" : "ezap-dev";

  useEffect(() => {
    enquireScreen(status => setIsMobile(status));

    const fetchData = async () => {
      try {
        const dataPath = `https://storage.googleapis.com/${bucket}/distance-education/data.json`;
        const collegeDataRequest = await axios.get(dataPath);

        setCollegeData(collegeDataRequest.data || {});
      } catch (error) {
        setErrorSubtitle(
          error.message || "Something went wrong. Please try again later"
        );
        setErrorStatus(true);
      }
    };
    fetchData();
  }, [bucket]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {errorStatus ? (
        <Result status="500" title="Error!!!" subTitle={errorSubtitle} />
      ) : data && data.length ? (
        <React.Fragment>
          <Header
            id="header"
            key="header"
            dataSource={HeaderDataSource}
            isMobile={isMobile}
          />

          {data.map(item => {
            return (
              <ScrollableAnchor key={item.id} id={item.id}>
                <Row
                  type="flex"
                  justify="center"
                  className={item.layout.backgroundColor}
                >
                  <Col
                    className={item.class}
                    {...item.layout.sectionGridConfig}
                    type="flex"
                    align="middle"
                  >
                    <Test
                      component={item.components}
                      data={item.data}
                      layout={item.layout}
                      component_id={item.id}
                    />
                  </Col>
                </Row>
              </ScrollableAnchor>
            );
          })}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
export default HomePageComponent;
