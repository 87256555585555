import React from "react";
import { Row, Col, Card } from "antd";
import Heading from "./Heading";

const Services = ({ data, layout, isMobile }) => {
  const { values } = data;
  const { headerColor, gridConfig } = layout;

  return (
    <React.Fragment>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
        />
      )}
      <Row
        type="flex"
        className="quality-card"
        justify="center"
        gutter={[{ xs: 24, md: 32, lg: 24 }, 20]}
      >
        {values.map(service => {
          return (
            <Col {...gridConfig} key={service.title} type="flex">
              <Card>
                <h2 className="half-underline" style={{ marginRight: "2rem" }}>
                  {service.title}
                </h2>
                <p style={{ marginTop: "4.5rem" }}>{service.description}</p>
              </Card>
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};
export default Services;
