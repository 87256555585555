import React from "react";
import "./styles/App.css";
import "antd/dist/antd.css";

import HomePageComponent from "./pages/HomePageComponent";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route component={HomePageComponent} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
